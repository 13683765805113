@charset "UTF-8";
@font-face {
  font-family: "FontAwesome";
  src: url("../font/fontawesome-webfont.eot") format("embedded-opentype"), url("../font/fontawesome-webfont.otf") format("opentype"), url("../font/fontawesome-webfont.woff") format("woff"), url("../font/fontawesome-webfont.ttf") format("truetype"), url("../font/fontawesome-webfont.svg#$fitxer") format("svg");
  font-weight: "normal";
  font-style: normal; }

@font-face {
  font-family: "Din";
  src: url("../font/DINPro-Light.eot") format("embedded-opentype"), url("../font/DINPro-Light.otf") format("opentype"), url("../font/DINPro-Light.woff") format("woff"), url("../font/DINPro-Light.ttf") format("truetype"), url("../font/DINPro-Light.svg#$fitxer") format("svg");
  font-weight: 100;
  font-style: normal; }

@font-face {
  font-family: "Din";
  src: url("../font/DINPro-Regular.eot") format("embedded-opentype"), url("../font/DINPro-Regular.otf") format("opentype"), url("../font/DINPro-Regular.woff") format("woff"), url("../font/DINPro-Regular.ttf") format("truetype"), url("../font/DINPro-Regular.svg#$fitxer") format("svg");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: "Din";
  src: url("../font/DINPro-Medium.eot") format("embedded-opentype"), url("../font/DINPro-Medium.otf") format("opentype"), url("../font/DINPro-Medium.woff") format("woff"), url("../font/DINPro-Medium.ttf") format("truetype"), url("../font/DINPro-Medium.svg#$fitxer") format("svg");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: "Din";
  src: url("../font/DINPro-Bold.eot") format("embedded-opentype"), url("../font/DINPro-Bold.otf") format("opentype"), url("../font/DINPro-Bold.woff") format("woff"), url("../font/DINPro-Bold.ttf") format("truetype"), url("../font/DINPro-Bold.svg#$fitxer") format("svg");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: "Din";
  src: url("../font/DINPro-Black.eot") format("embedded-opentype"), url("../font/DINPro-Black.otf") format("opentype"), url("../font/DINPro-Black.woff") format("woff"), url("../font/DINPro-Black.ttf") format("truetype"), url("../font/DINPro-Black.svg#$fitxer") format("svg");
  font-weight: 900;
  font-style: normal; }

/*codi previ*/
body, .wrap {
  font-family: 'Din';
  margin: 0;
  padding: 0;
  width: 100%;
  font-size: 16px;
  display: table;
  font-weight: 300;
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: geometricPrecision;
  max-width: 100vw;
  overflow-x: hidden;
  color: #5f6163; }

.wrap {
  opacity: 0; }

small {
  font-weight: 200;
  color: gray; }

.container {
  text-align: center;
  display: table-cell;
  vertical-align: middle; }

.content {
  text-align: center;
  display: inline-block; }

.title {
  font-size: 96px; }

/**/
::-moz-selection {
  background: #221e20;
  color: #fff; }

::selection {
  background: #221e20;
  color: #fff; }

header, main, footer, section, article, aside, nav, #details {
  float: left; }

footer, #details {
  width: 100%;
  padding: 15px 0;
  color: #fff;
  line-height: 1em; }

.logo {
  max-width: 28px;
  max-width: 170px;
  width: 100%;
  height: 100%;
  padding-top: 4px; }

.fleft {
  float: left; }

.fright {
  float: right; }

.pleft0 {
  padding-left: 0; }

.pright0 {
  padding-right: 0; }

.tleft {
  text-align: left; }

.tright {
  text-align: right; }

.tcenter {
  text-align: center; }

.mt10 {
  margin-top: 10px; }

.fw300 {
  font-weight: 300; }

.fw500 {
  font-weight: 500; }

.fw700 {
  font-weight: 700; }

.ttlow {
  text-transform: lowercase; }

.ttup {
  text-transform: uppercase; }

.ttnone {
  text-transform: none; }

.op0 {
  opacity: 0; }

/*Bootstrap overwritting*/
.btn, .btn:hover, select, option {
  font-size: 1em;
  text-transform: none;
  color: #5f6163;
  font-weight: 300; }

.btn-default, .btn-default:hover, .btn-default:focus {
  background: #fff;
  border-color: #fff;
  overflow: hidden;
  color: #5f6163; }

.btn-success, .btn-success:hover, .btn-success:focus, .btn-success:active:hover, .btn-success:active:focus {
  color: #5f6163;
  background: #fff;
  border-color: #e01a32; }

.btn-info {
  background: #e01a32;
  background: -ms-linear-gradient(to right, #e01a32 0%, #b91825 100%);
  filter: "progid:DXImageTransform.Microsoft.gradient( startColorstr='$color1', endColorstr='$color2',GradientType=1 )";
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=$color1, endColorstr=$color2)";
  background: linear-gradient(to right, #e01a32 0%, #b91825 100%);
  zoom: 1;
  border-color: #e01a32;
  color: #fff; }

.btn-info:hover, .btn-info:focus {
  background: #e00b28;
  background: -ms-linear-gradient(to right, #e00b28 0%, #ba0d19 100%);
  filter: "progid:DXImageTransform.Microsoft.gradient( startColorstr='$color1', endColorstr='$color2',GradientType=1 )";
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=$color1, endColorstr=$color2)";
  background: linear-gradient(to right, #e00b28 0%, #ba0d19 100%);
  zoom: 1;
  border-color: #e01a32;
  color: #fff; }

.btn-info[disabled], .btn-info[disabled]:hover, .btn-info[disabled]:focus {
  background: #aaa;
  background: -ms-linear-gradient(to right, #aaa 0%, #888 100%);
  filter: "progid:DXImageTransform.Microsoft.gradient( startColorstr='$color1', endColorstr='$color2',GradientType=1 )";
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=$color1, endColorstr=$color2)";
  background: linear-gradient(to right, #aaa 0%, #888 100%);
  zoom: 1;
  border-color: #c7c7c7;
  color: #fff; }

.form-control:focus {
  box-shadow: none;
  border-color: #e01a32; }

a {
  color: #e01a32;
  text-decoration: none; }

#details a, footer a, #details a:hover, footer a:hover {
  color: #fff;
  text-decoration: none; }

h1, .h1 {
  font-weight: 700; }

h2, h3, h4, h5, h6, .h2, .h3, .h4, .h5, .h6 {
  font-weight: 300; }

b, strong {
  font-weight: 700; }

#home section {
  height: 100%;
  width: 100%; }
  #home section article {
    height: 100%;
    overflow: hidden;
    cursor: pointer;
    margin-bottom: 10px;
    padding-left: 0;
    padding-right: 0;
    position: relative;
    /*&.xs{max-width:50%;}*/ }
    #home section article .option {
      height: 10vh;
      /* ie min-height fix */
      color: #fff;
      padding-left: 0;
      padding-right: 0;
      /* @include flexbox;
				 -webkit-align-items: center;
				 align-items: center;
				 -ms-flex-pack: center;*/
      position: absolute;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      transition: all ease-in-out 1.2s;
      float: left;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: #fff;
      background-blend-mode: multiply; }
      #home section article .option.xs {
        width: 100%;
        margin: 0 !important;
        /*background-size:100% auto;*/ }
        #home section article .option.xs div p {
          width: 90%;
          margin: 0 5%; }
      #home section article .option.obra {
        background-image: url("/images/home_obranueva.jpg");
        background-position: 22% 40%;
        margin-right: 15px; }
      #home section article .option.rehab {
        background-image: url("/images/home_rehabilitacion.jpg");
        background-position: 10% 80%;
        margin-left: 15px; }
      #home section article .option b {
        width: 80%;
        margin: 10px auto;
        border: 1px solid #fff;
        border-radius: 6px;
        background: transparent;
        color: #fff;
        padding: 6px 75px;
        font-size: 1.1em;
        line-height: 1.8em;
        float: none;
        transition: all ease 0.4s; }
      #home section article .option div {
        position: absolute;
        top: 50%;
        left: 0px;
        right: 0px;
        width: 100%;
        transition: all ease 0.4s; }
        #home section article .option div p {
          width: 60%;
          margin: 0 20% 20px; }
      #home section article .option * {
        text-align: center; }
    #home section article:hover .option {
      background-color: #888;
      /*background-size:auto 110%;
					background-position:50% 50%;
					&.xs{background-size:110% auto;}*/ }
      #home section article:hover .option b {
        background: rgba(255, 255, 255, 0.6);
        border: none; }
    #home section article h2 {
      margin-bottom: 15px;
      font-weight: 700;
      margin-top: 0; }
    #home section article p {
      margin-bottom: 25px;
      min-height: 140px; }
  #home section #details {
    background: #221e20; }
  #home section.xs article {
    margin-bottom: 0px; }
    #home section.xs article .option b, #home section.xs article .option p {
      display: none; }

#home footer {
  background: #221e20; }
  #home footer p {
    margin-bottom: 0; }
    #home footer p span:first-child:after {
      content: '|';
      margin: 0 5px; }

#home footer, #home details {
  text-align: left; }
  #home footer p, #home details p {
    margin-bottom: 0; }
  #home footer a, #home details a {
    color: #fff; }

#home .xs footer, #home .xs details {
  text-align: center; }
  #home .xs footer p, #home .xs details p {
    margin-bottom: 12.5px; }

select.form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #fff url("/images/boto-desplegable.png") no-repeat scroll 100% 50%/contain;
  padding-right: 48px;
  font-size: 1em; }
  select.form-control::-ms-expand {
    display: none; }
  select.form-control:focus {
    /* option{
    	 	background: $pastilla !important;
    	 	color:$gristipo !important;
    	 	&:checked,&:hover{
    	 		border:1px solid $roig;
	     		box-shadow: 0 0 10px 100px $pastilla inset;
	     		color:$gristipo !important;
    	 	}
    	}*/ }

body .tancar {
  position: absolute;
  height: 0%;
  width: 100%;
  padding: 0 15px;
  background: #fff url("/images/header_obranueva.png") no-repeat 50% 0%/cover;
  transition: all ease 0.4s;
  z-index: 30;
  overflow: hidden;
  margin-right: -15px;
  margin-left: -15px; }
  body .tancar #close {
    position: absolute;
    height: 25px;
    width: 25px;
    right: 45px;
    transform: rotate(45deg);
    margin: 7px 0;
    cursor: pointer; }

body.on .tancar {
  height: 100%; }

#inner .buto {
  cursor: pointer;
  position: relative; }
  #inner .buto a svg {
    float: left;
    width: 100%;
    height: 100%;
    margin: 7.5px 0px;
    max-height: 25px;
    max-width: 25px; }

#inner header {
  position: relative;
  color: #fff;
  width: 100%;
  background: #fff no-repeat 50% 0%/cover; }
  #inner header.obranueva {
    background-image: url("/images/header_obranueva.png"); }
  #inner header.rehabilitacion {
    background-image: url("/images/header_rehabilitacion.png"); }
  #inner header img {
    padding: 10px 0; }
  #inner header h1 {
    margin: 0;
    line-height: 20px;
    font-size: 20px;
    padding: 10px 0;
    padding-left: 30px; }

#inner.xs header h1 {
  padding-left: 0;
  margin-left: -15px;
  margin-right: -15px;
  letter-spacing: -0.6px; }

#inner section {
  position: relative;
  width: 100%; }
  #inner section img {
    width: 100%;
    height: 100%;
    margin: 10px 0px;
    margin-bottom: 10px; }
    #inner section img[src~='renders'] {
      max-width: 500px;
      max-height: 500px; }
  #inner section #clima_mapa {
    /*max-width: 388.75px;max-height: 340.283px;*/ }
  #inner section h4 {
    padding-top: 30px;
    padding-bottom: 8.5px;
    margin: 0;
    padding-left: 30px;
    font-size: 21px; }
  #inner section .svgs-xs {
    width: 100%;
    float: left; }
    #inner section .svgs-xs div.dinssvg {
      float: left;
      position: relative; }
      #inner section .svgs-xs div.dinssvg svg {
        width: 100%; }
      #inner section .svgs-xs div.dinssvg:before {
        position: absolute;
        bottom: 0;
        left: 0;
        border-radius: 50%;
        background: #606264;
        line-height: 1em;
        color: #fff;
        padding: 3px 7px 4px;
        font-weight: 700; }
      #inner section .svgs-xs div.dinssvg:nth-child(1) {
        width: 23.5%; }
        #inner section .svgs-xs div.dinssvg:nth-child(1):before {
          content: '1'; }
      #inner section .svgs-xs div.dinssvg:nth-child(2) {
        width: 45%; }
        #inner section .svgs-xs div.dinssvg:nth-child(2):before {
          content: '2'; }
      #inner section .svgs-xs div.dinssvg:nth-child(3) {
        width: 29.5%; }
        #inner section .svgs-xs div.dinssvg:nth-child(3):before {
          content: '3'; }
  #inner section.xs {
    position: relative; }
    #inner section.xs .flexy {
      min-height: 100px; }
    #inner section.xs .container-fluid {
      padding-left: 0;
      padding-right: 0; }
    #inner section.xs nav, #inner section.xs aside {
      top: 0;
      position: absolute;
      z-index: 25;
      display: none; }
    #inner section.xs aside {
      transform: translateX(-100%); }
      #inner section.xs aside h4 {
        background: #fff; }
    #inner section.xs nav {
      transform: translateX(-100%); }
    #inner section.xs main {
      z-index: 15; }
    #inner section.xs .svgs-xs div.dinssvg {
      /*
					&:nth-child(1){width: 100%;}
					&:nth-child(2){width: 100%;}
					&:nth-child(3){width: 100%;}
					*/ }
    #inner section.xs #details {
      margin-right: 0px;
      margin-left: 0px; }
  #inner section header {
    background: none; }
  #inner section #details {
    background: #e01a32;
    position: absolute;
    bottom: 0;
    z-index: 30;
    margin-right: -15px;
    margin-left: -15px; }
  #inner section aside img[src$='edificio1.png'] {
    max-width: 101px;
    max-height: 127px;
    vertical-align: bottom; }
  #inner section aside img[src$='edificio2.png'] {
    max-width: 140px;
    max-height: 70px; }
  #inner section aside img[src$='edificio3.png'] {
    max-width: 60px;
    max-height: 75px; }
  #inner section aside p {
    font-weight: 300; }
  #inner section b span.num {
    border-radius: 50%;
    background: #606264;
    line-height: 1em;
    color: #fff;
    padding: 0 7px;
    margin-right: 7px; }
  #inner section .checkbox {
    line-height: 14px; }

#inner footer, #inner details {
  text-align: left; }
  #inner footer p, #inner details p {
    margin-bottom: 0; }
  #inner footer a, #inner details a {
    color: #fff; }

#inner .xs footer, #inner .xs details {
  text-align: center; }
  #inner .xs footer p, #inner .xs details p {
    margin-bottom: 12.5px; }

#inner footer {
  background: #e01a32; }
  #inner footer .center-xs {
    text-align: left; }

#inner footer.xs {
  position: fixed;
  -webkit-backface-visibility: hidden;
  bottom: 0;
  z-index: 30; }
  #inner footer.xs .center-xs {
    text-align: center; }

#inner nav {
  background: rgba(255, 255, 255, 0.9); }
  #inner nav .pas {
    margin-bottom: 30px;
    cursor: default; }
    #inner nav .pas.active {
      color: #221e20; }
    #inner nav .pas dt {
      color: #5f6163;
      line-height: 18px;
      font-weight: 700; }
    #inner nav .pas dd {
      line-height: 18px;
      font-size: 0.9em; }
      #inner nav .pas dd p {
        text-transform: none;
        margin-bottom: 0;
        color: #e01a32;
        position: relative;
        padding-left: 15px; }
        #inner nav .pas dd p:before {
          content: '';
          position: absolute;
          left: 0;
          width: 12px;
          background: url("/images/fletxa.png") no-repeat 50% 50%/contain;
          height: 10px;
          margin: 4px 0 0; }
    #inner nav .pas.active dt {
      color: #221e20; }
    #inner nav .pas.active dd {
      color: #e01a32; }
    #inner nav .pas a {
      color: inherit;
      text-decoration: none; }
      #inner nav .pas a:hover {
        color: inherit;
        text-decoration: none; }

#inner nav, #inner aside {
  z-index: 20;
  transition: transform ease 0.4s; }

#inner aside {
  text-transform: none; }
  #inner aside .isel {
    transition: opacity ease 0.4s;
    opacity: 0;
    display: none; }
    #inner aside .isel.active {
      opacity: 1;
      display: block; }
  #inner aside .flexy {
    /* OLD: Safari,  iOS, Android browser, older WebKit browsers.  */
    /* OLD: Firefox (buggy) */
    display: -ms-flexbox;
    /* MID: IE 10 */
    /* NEW, Chrome 21–28, Safari 6.1+ */
    display: flex;
    /* NEW: IE11, Chrome 29+, Opera 12.1+, Firefox 22+ */
    -ms-flex-line-pack: center;
    align-content: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;
    text-align: center;
    background: #fff;
    min-height: 200px;
    margin-bottom: 10px; }
    #inner aside .flexy.verd {
      background: #99cca3;
      background: -ms-linear-gradient(to right, #99cca3 0%, #6ebb83 100%);
      filter: "progid:DXImageTransform.Microsoft.gradient( startColorstr='$color1', endColorstr='$color2',GradientType=1 )";
      -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=$color1, endColorstr=$color2)";
      background: linear-gradient(to right, #99cca3 0%, #6ebb83 100%);
      zoom: 1;
      color: #fff; }
    #inner aside .flexy.griset {
      background: #5f6163;
      color: #fff;
      padding: 15px;
      text-align: left; }

#inner .dins {
  overflow: hidden;
  border-radius: 6px;
  background: #e6e7e7;
  height: 100%;
  padding: 25px 30px 40px;
  position: relative; }
  #inner .dins.xs {
    margin-right: -15px;
    margin-left: -15px;
    border-radius: 0px; }
  #inner .dins #proces {
    position: absolute;
    bottom: 12px;
    width: calc(100% - 60px);
    /* width: calc(100% - 60px);*/ }

.amunt {
  transform: rotate(90deg);
  height: 40px;
  width: 40px;
  position: fixed;
  z-index: 40;
  right: 30px;
  cursor: pointer;
  display: none; }

#preloader {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100px;
  height: 100px;
  margin-top: -50px;
  margin-left: -50px;
  animation: cub 0.5s ease 0s infinite alternate; }
  #preloader svg {
    width: 100%;
    height: 100%; }

@keyframes cub {
  from {
    opacity: 0.5; }
  to {
    opacity: 1; } }

svg .fnone {
  fill: none; }

svg .f-FFF {
  fill: #FFFFFF; }

svg .f-DA0D26 {
  fill: #DA0D26; }

svg .f-606264 {
  fill: #606264; }

svg .s-FFF {
  stroke: #FFFFFF; }

svg .s-E62333 {
  stroke: #E62333; }

svg .sm10 {
  stroke-miterlimit: 10; }

svg .sw1 {
  stroke-width: 1.5; }

svg .sw2 {
  stroke-width: 2.5; }

svg .ffdin {
  font-family: din; }

svg .fs10 {
  font-size: 10.4746px; }

svg g rect {
  width: 7.25px;
  height: 11.25px;
  fill: none; }

svg g text {
  fill: #FFFFFF;
  font-size: 10.4746px; }

svg g:nth-child(3) .circle {
  transform: translate(0px, 0px); }

svg g:nth-child(3) .rect {
  transform: translate(2.65px, 3.46px); }

svg g:nth-child(3) text {
  transform: matrix(1, 0, 0, 1, 4.5647, 10.9224); }

svg g:nth-child(4) .circle {
  transform: translate(57px, 0px); }

svg g:nth-child(4) .rect {
  transform: translate(61.7px, 3.46px); }

svg g:nth-child(4) text {
  transform: matrix(1, 0, 0, 1, 62.5906, 10.9224); }

svg g:nth-child(5) .circle {
  transform: translate(115px, 0px); }

svg g:nth-child(5) .rect {
  transform: translate(119.67px, 3.46px); }

svg g:nth-child(5) text {
  transform: matrix(1, 0, 0, 1, 120.5579, 10.9224); }

svg g:nth-child(6) .circle {
  transform: translate(173px, 0px); }

svg g:nth-child(6) .rect {
  transform: translate(177.63px, 3.46px); }

svg g:nth-child(6) text {
  transform: matrix(1, 0, 0, 1, 178.5276, 10.9224); }

svg g:nth-child(7) .circle {
  transform: translate(232px, 0px); }

svg g:nth-child(7) .rect {
  transform: translate(235.6px, 3.46px); }

svg g:nth-child(7) text {
  transform: matrix(1, 0, 0, 1, 236.4934, 10.9224); }

svg g:nth-child(8) .circle {
  transform: translate(290px, 0px); }

svg g:nth-child(8) .rect {
  transform: translate(293.57px, 3.46px); }

svg g:nth-child(8) text {
  transform: matrix(1, 0, 0, 1, 294.4612, 10.9224); }

svg g:nth-child(9) .circle {
  transform: translate(347px, 0px); }

svg g:nth-child(9) .rect {
  transform: translate(351.54px, 4.54px); }

svg g:nth-child(9) text {
  transform: matrix(1, 0, 0, 1, 352.4299, 10.9224); }

svg g.inactiu {
  cursor: default; }
  svg g.inactiu .circle {
    fill: #C8C8CA; }

svg g.current .circle {
  fill: #E30922; }

svg g.current text {
  font-weight: 500; }

svg g.actiu .circle {
  fill: #F18290; }

#admin {
  min-width: 100vw;
  min-height: 100vh;
  width: 50vw;
  height: 50vh; }
  #admin .form-group {
    width: 100%;
    padding-bottom: 10px; }
  #admin .btn-success, #admin .btn-success:hover {
    color: #e01a32; }
  #admin .panel-roig {
    border-color: #ddd; }
  #admin .panel-roig > .panel-heading {
    background: #e01a32;
    background: -ms-linear-gradient(to right, #e01a32 0%, #b91825 100%);
    filter: "progid:DXImageTransform.Microsoft.gradient( startColorstr='$color1', endColorstr='$color2',GradientType=1 )";
    -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=$color1, endColorstr=$color2)";
    background: linear-gradient(to right, #e01a32 0%, #b91825 100%);
    zoom: 1;
    color: #fff; }

/*# sourceMappingURL=app.css.map */
